import logo from './logo.svg';
import './App.css';
import { Home } from './components/home';
import { Video } from './components/video';
import { Merch } from './components/merch';
import { Music } from './components/music';
import { Game } from './components/game';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/music">
            <Music />
          </Route>
          <Route path="/video">
            <Video />
          </Route>
          <Route path="/merch">
            <Merch />
          </Route>
          <Route path="/game">
            <Game />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// function Home() {
//   return <h2>Home</h2>;
// }

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}