import React from 'react';

export const Game: React.FC = () => {
    return (
        // <div id="music-content">
        //     <iframe  id="video-background" src="http://pezgame.surge.sh/" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        // </div>
        <div id="music-content">
            <iframe  id="video-background" src="http://pezgame.surge.sh/" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
};