import React,{ useState } from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import './home.css';

export const Home: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState();
    const [successMsg, setSuccessMsg] = useState(true);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // fetch(`http://localhost:3000/welcome/phoneNumber/${phoneNumber}`, {
        fetch(`https://pez-society-api.herokuapp.com/welcome/phoneNumber/${phoneNumber}`, {
            method: 'post',
            headers: {'Content-Type':'application/json'}
        }).then(response => {
            if(response.status === 200) {
              setSuccessMsg(false);
            }
          })
    };


    return (
      <div className="container">
          <pre className="preContainer">{`
                ... P e Z  S o c i e t y ...

|:::::::::::::::: :::::::::::::::::::::::'.::::: :::::::::::|
|                                                           | 
|                   __ooooooooooooooooo__                   | 
|                 oOOOOOOOOOOOOOOOOOOOOOOOo                 |
|             oOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOo            | 
|          oOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOo          |
|        oOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOo        |
|      oOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOo      |
|     oOOOOOOOOOOO*  *OOOOOOOOOOOOOOOO*  *OOOOOOOOOOOOo     |
|    oOOOOOOOOOOO      OOOOOOOOOOOOOO      OOOOOOOOOOOOo    |
|   oOOOOOOOOOOOOOo  oOOOOOOOOOOOOOOOOo  oOOOOOOOOOOOOOOo   |
|   oOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOo   |
|   oOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOo   |
|   oOOOO     OOOOOOOOOOOOOOO0OOOOOOOOOOOOOOOOOO     OOOOo  |
|   oOOOOOO OOOOOOOOOOOOOOOOOOOOO0OOOOOOOOOOOOOOOO OOOOOOo  |
|    *OOOOO  OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO  OOOOOO*   |
|    *OOOOOO  *OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO*  OOOOOO*    |
|     *OOOOOO  *OOOOOOOOOOOOOOOOOOOOOOOOOOOOO*  OOOOOO*     |
|      *OOOOOOo  *OOOOOOOOOOOOOOOOOOOOOOOOO*  oOOOOOO*      |
|        *OOOOOOOo  *OOOOOOOOOOOOOOOOOOO*  oOOOOOOO*        |
|          *OOOOOOOOo  *OOOOOOOOOOOOO*  oOOOOOOOO*          |   
|             *OOOOOOOOo              oOOOOOOOO*            |  
|              *OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO*             |  
|                   *OOOOOOOOOOOOOOOOOOOOO*                 |  
|                       *OOOOOOOOOOOO*                      |
|                                                           | 
|:::::::::::::::: :::::::::::::::::::::::'.::::: :::::::::::|
.  . ....................... ...... .... ............... .  . 
_______________________                 _____________________
                          \\ - x X x - /

        `}

          <div className="navContainer"> 
            <div className="headline">
              Welcome to the <br/>
              <i>P e Z  S o c i e t y</i>
            </div>


            <Link to="/music">* Music </Link>
            <Link to="/video">* Video </Link>
            <Link to="/merch">* Merch </Link>
            <Link to="/game">* Game  </Link>

            <div className="footerText">peace!</div>
          </div>
          {`



\\//~\\| |    |\\ |~)|_~   | ||\\ ||/~\\| ||_~
| \\_/\\_/    |~\\|~\\|__   \\_/| \\||\\_X\\_/|__

`}
<div>(Q U E S T I O N   E V E R Y T H I N G)</div>

{successMsg && (
  <div className="inputContainer"> 
      <form onSubmit={e => { handleSubmit(e) }}>
        <label>
            Phone Number:
            <br/>
            <input 
            name='phoneNumber'
            className="joinInput" 
            type='text' 
            value={phoneNumber}
            placeholder="19528003312"
            onChange={(e: any) => setPhoneNumber(e.target.value)}
            />
        </label>
        <input className="joinBtn" type="submit" value="Submit" />
    </form>   
  </div>
)}
{!successMsg && (
  <div className="inventationMsg">
    <div>Your inventaion has been sent.</div>
  </div>
)}
{`    

              _________
            / ======== \\
           / __________ \\
          | ___________ |
          | |         | |
          | |   PeZ   | |
          | |_________| |____________
          \\_____________/            )
          / """"""""""" \\           /
         / ::::::::::::: \\     =D-'
        (_________________)

                      
  `}</pre>
  </div>
    );
};